import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { WebLayoutService } from "@services";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ListResultDto } from "@modules/models";
import { DataTableComponent } from "../data-table";

export interface Paged {
  pageNumber: number;
  pageSize: number;
}

@Component({
  selector: "abi-pager",
  templateUrl: "./pager.component.html",
  styleUrls: ["./pager.component.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PagerComponent),
    multi: true
  }]
})
export class PagerComponent implements OnInit, ControlValueAccessor, OnChanges {
  // New properties (optional opt-in: gives session pagination saving and loading)
  @Input() dataTable: DataTableComponent;
  @Input() pageName: string;
  // pageName: string;
  dataO: Subject<ListResultDto<any>>;
  // Previous Input for flexible implementations (old properties)
  @Input() totalResults: number;
  @Input() pagedO: Subject<Paged>;
  @Input() pageNumber = 1;
  // @Input() pageId = "pager";
  @Input() disabled = false;
  @Output() paged: EventEmitter<Paged> = new EventEmitter();
  pageSize: number;
  pageSizes = [20, 50, 100];
  maxPages: Observable<number>;
  mPages: number = 6;

  private _onTouched = () => { };
  private _onChange = (_: any) => { };

  constructor(private responsive: BreakpointObserver, private layoutService: WebLayoutService) {
    // this.pageSize = Math.max(+localStorage.getItem("pageSize"), 20);
    // this.maxPages = this.responsive.observe(Breakpoints.Handset).pipe(map(bp => bp.matches ? 1 : 6));
    this.responsive.observe(Breakpoints.Handset).pipe(map(bp => bp.matches ? 1 : 6)).subscribe(m => this.mPages = m);
  }

  ngOnChanges(changes: SimpleChanges): void {

    // TODO: For future use - convert to use DataTableComponent
    if(changes.dataTable){
      console.log("PagerComponent: dataTable changed", this.dataTable, this.dataTable.pageName);
      this.pageName = this.dataTable.pageName;// this is set once the dataTable is set
      this.pagedO = this.dataTable.pagedS;// this should only be set once (so that Table can re-query as Page changes)
      // this.totalResults = this.dataTable.totalResults;// this updates separately from the dataTable
      if(!this.dataO) {
        this.dataO = this.dataTable.dataO;// this should only be set once (so that Pagination can react to new data)
        this.dataO.subscribe(data => {
          this.totalResults = data.totalResults;
          // this.doPaged();
        });
      }
      this.pageNumber = this.dataTable.paged?.pageNumber || 1;// more of an initial value role here
    }
  }

  ngOnInit(): void {
    this.loadPagination();
    this.doPaged(this.pageNumber);
  }

  writeValue(paged: any): void {
    if (paged) {
      this.pageSize = paged.pageSize || this.pageSizes[0];
      this.pageNumber = paged.pageNumber || 1;
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setPageSize(size: number) {
    this.pageSize = size;
    this.pageNumber = 1;
    this.doPaged(this.pageNumber);
  }

  doPaged(newPage?: number) {
    this.pageNumber = newPage || this.pageNumber || 1;
    this.pageSize = this.pageSize || this.pageSizes[0];
    const data = { pageNumber: this.pageNumber, pageSize: this.pageSize };
    this.savePagination(data);
    this._onChange(data);
    if (this.pagedO) {
      this.pagedO.next(data);
    }
    this.paged.emit(data);
    this._onTouched();
    console.log("PagerComponent: doPaged", this.pageName, data);
  }

  savePagination(data: Paged) {
    if(this.pageName)
    this.layoutService.setSessionData(this.pageName + "_pagination", data);
  }

  loadPagination() {
    const data = this.pageName ? this.layoutService.getSessionData(this.pageName + "_pagination") : null;
    if (data) {
      this.pageNumber = data.pageNumber;
      this.pageSize = data.pageSize;
    }
    console.log("PagerComponent: loadPagination", this.pageName, data);
  }
}
